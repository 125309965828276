<template>
    <div class="flex gap-3 items-center overflow-x-auto">
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
            </svg>
        </div>
        <button @click="sortByCategory('all',$event)" class="flex min-w-max gap-2 items-center border-b-4 rounded category-active">
            Popular items
        </button>
        <button v-for="category in categories" :key="category"  @click="sortByCategory(category.id,$event)" class="flex gap-3 min-w-max items-center border-b-4 rounded category-none">
            {{ category.name }}
        </button>
    </div>
</template>
<script>
export default {
    name:"Categories",
    props:['categories'],
    emits:['sortCategory'],
    methods:{
        sortByCategory(categoryID,event){
            const pills = document.querySelectorAll('.category-active');
            for (let i = 0; i < pills.length; i++) {
                const element = pills[i];
                 if (element.classList.contains('category-active')) {
                    element.classList.remove('category-active')
                    element.classList.add('category-none')
                }   
            }
            event.target.classList.remove('category-none');
            event.target.classList.add('category-active');
            this.$emit('sortCategory',categoryID)
        }
    }
}
</script>