<template>
<div v-show="!deleteItem" class=" text-sm border-white py-2 border-b-2">
    <div class="grid grid-cols-3">
        <div class="">
            <img :src="container.container.images[container.container.images.length  - 1]" class="h-20 w-20 md:h-24 md:w-24 object-cover rounded" alt="">
        </div> 
        <div class="col-span-2">
            <div>
                <div class="grid grid-cols-2 w-full">
                    <p class="text-sm md:text-base font-semibold">Container {{ position + 1 }}</p>
                    <div class="flex-grow">
                        <div v-if="name != 'orderSummary'" class="flex justify-end items-center">
                            <button @click="deleteContainer()" class="text-red-600">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="text-xs mb-3 text-ellipsis">
                    Items: <span v-for="item,index in container.cartItems" :key="item">{{ item.foodName }}({{ item.quantity }})<span v-if="index + 1 != container.cartItems.length">,</span></span>
                </div>
                <div class="grid grid-cols-2  mt-2">
                    <div v-if="name != 'orderSummary'" class="flex space-x-2">
                        <button class="bg-brand text-black px-3" @click="minusQty" >-</button>
                        <p class="border py-1 px-3 flex items-center">{{ Quantity }}</p>
                        <button class="bg-brand text-black px-3 " @click="addQty">+</button>
                    </div>
                    <div v-else >
                       Quantity: {{ Quantity }}                              
                    </div>
                    <div class="w-full">
                        <div v-if="calculateContainerPrice" :class="{'justify-start': name == 'orderSummary', 'justify-end': name != 'orderSummary'}"  class="flex flex-grow ">
                            <p class="flex items-center text-sm md:text-base  text-right font-semibold">&#8358; {{ this.global.formatNumber(calculateContainerPrice) }}</p>
                        </div>  
                    </div>
                </div> 
            </div>    
        </div>
    </div>
</div>
</template>
<script>
export default{
    name:"ContainerCart",
    props:['container','position','name'],
    data(){
        return {
           Quantity: this.container.quantity,
           QuantityPrice:0,
           deleteItem:false,
           amount:0
        }
    },
    computed:{
        calculateContainerPrice(){
            let cost = 0;
            this.container.cartItems.forEach((item)=>{
                cost = cost + (item.quantity * item.amount)
            })
            this.QuantityPrice = cost + this.container.container.price

            return this.container.quantity * (cost + this.container.container.price)
        }
    },
    methods:{
        addQty(){
            this.Quantity++
            this.updateContainer();
        },
        minusQty(){
            if (this.Quantity > 1 ) {
                this.Quantity--
                this.updateContainer()
            }

        },
        updateContainer(){
            let cart = this.$store.getters.getCart
            cart.containers.map((item,index)=>{
                if(index == this.position){
                    item.quantity = this.Quantity
                }
            })
            this.$store.dispatch('addCart',cart)
        },
        deleteContainer(){
            let cart = this.$store.getters.getCart
            let container = cart.containers.find((item,index) =>  index == this.position);
            if(container.cartItems.length){
                container.cartItems.forEach((element) => {
                    let meal = cart.cartItems.find(item => item.foodId == element.foodId)
                    if(meal){
                        cart.cartItems.map((item) => {
                            if(item.foodId == element.foodId){
                                item.quantity = item.quantity + element.quantity
                            }
                        });
                    }else{
                        //add to portion
                        cart.cartItems.push(element)
                    }
                });
            }
           
            cart.containers = cart.containers.filter((item,index) => index != this.position );
            this.$store.dispatch('addCart',cart)
            this.deleteItem = true;
        }

    }

}
</script>