<template>
    <div class="bg-gray-100 relative mb-16 z-10">
        <img :src="header" class="w-full h-44 lg:h-64 -z-10 object-cover rounded-lg" alt="">
        <div class="absolute top-0 w-full h-full bg-overlay flex justify-center rounded-lg items-center">
            <!-- <div class="text-gray-100">
                <h2 class="lg:text-5xl text-3xl font-semibold text-center">{{name}}</h2>
                <p class="text-sm text-center ">{{ address }}</p>
            </div> -->
        </div>
        <div class="absolute left-8 lg:left-12 -bottom-12 lg:-bottom-14">
            <div class="h-24 w-24 lg:-mt-28 lg:h-32 lg:w-32  shadow-lg rounded-full bg-white flex items-center justify-center" >
                <img v-if="logo !== 'string'" :src="logo" class="h-full w-full rounded-full" alt="">
                <img v-else src="@/assets/img/noshtribe_logo-black.jpg" class="h-14 w-14 lg:h-36 lg:w-36 " alt="">
            </div>
        </div>
    </div>
    <div class="flex items-center justify-between">
        <div>
            <h2 class="lg:text-4xl text-xl font-semibold">{{ name }}</h2>
            <div class="flex items-center text-sm space-x-5">
                <div class="text-gray-600">
                    <div class="font-semibold">
                        <div class="flex space-x-1 items-center"> 
                            <div class="flex items-center" v-if="rating != null && rating != 0">
                                <star-rating :increment="0.5" :rating="(rating != null && rating != 'NaN') ? parseInt(rating.toFixed(1)): 1" inactive-color="#fff" border-color="#000" active-color="#DAA520" :border-width="2" :star-size="12" :read-only=true  :show-rating=false />
                            </div>
                            <div class="flex items-center" v-else>
                                <p>Not rated</p>
                            </div>
                            <div :class="{'mt-1': rating}" class="flex text-sm items-center">
                                <button @click="toogleReviews">({{ (reviews != null) ? reviews : 0 }} {{ (reviews > 1) ? 'reviews' : 'review' }})</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <button class="text-xs flex items-center gap-2 bg-gray-300 p-3 rounded">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>   
                </div>
                <div>More info</div>
            </button>
        </div>
    </div>
    <ReviewsPopup v-if="showReviews" :reviews="reviews" :reviewsData="reviewsData" :rating="rating" @closePopup="toogleReviews"/>
</template>

<script>
import DeliveryAddress from "@/components/DeliveryAddress.vue";
import Modal from "@/components/Modal.vue";
import StarRating from 'vue-star-rating';
import ReviewsPopup from "@/components/RatingsModal.vue";
import moment from "moment";
export default {
    name:"VendorBreadcrumb",
    components:{
        DeliveryAddress,Modal,StarRating,ReviewsPopup
    },
    props:['header','name','address','time','logo','rating','reviews','reviewsData'],
    data(){
        return{
            showReviews:false,
        }
    },
    methods:{
        toogleReviews(){
            this.showReviews = !this.showReviews
        },
        formatThisdate(date){
            return moment(date).format('MMMM Do YYYY, h:mm a');
        },
        findTotalPercentage(type){
            let boundary = type - 0.5
            let Rating = this.reviewsData.filter(item => item.rating >= boundary && item.rating <= type );
            return Rating.length / this.reviewsData.length * 100 + '%'
        }
    },
    mounted(){
    }
}
</script>

<style>

</style>