<template>
  <div v-show="!deleteItem" class=" text-sm border-white py-2 border-b-2">
    <div class="grid grid-cols-3">
        <div class="">
            <img :src="data.foodImage" class="h-20 w-20 md:h-24 md:w-24 object-cover rounded" alt="">
        </div> 
        <div class="col-span-2">
            <div>
            <div class="grid grid-cols-2 w-full">
                <p class="text-sm md:text-base font-semibold">{{ data.foodName }}</p>
                <div class="flex-grow">
                    <div v-if="name != 'orderSummary'" class="flex justify-end items-center">
                        <button @click="deleteCart()" class="text-red-600">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="mb-2">
                <span v-if="data.foodType ==='COMBO'" >
                    <span  class="bg-orange-400 text-gray-100 px-2 py-1 text-xs rounded-full" >Combo</span>
                </span>
                <span v-else-if="data.foodType ==='PORTION'">
                    <span class="bg-amber-400 text-gray-100 px-2 py-1 text-xs rounded-full" >Extra/Portion</span>
                </span>
            </div>
            <div class="grid grid-cols-2  mt-2">
                    <div v-if="name != 'orderSummary'" class="flex space-x-2">
                        <button class="bg-brand text-black px-3" @click="minusQty" >-</button>
                        <p class="border py-1 px-3 flex items-center">{{ Quantity }}</p>
                        <button class="bg-brand text-black px-3 " @click="addQty">+</button>
                    </div>
                    <div v-else >
                       Quantity: {{ Quantity }}                              
                    </div>
                    <div class="w-full">
                        <div v-if="QuantityPrice" :class="{'justify-start': name == 'orderSummary', 'justify-end': name != 'orderSummary'}"  class="flex flex-grow ">
                            <p class="flex items-center text-sm md:text-base  text-right font-semibold">&#8358;{{formatNumber(QuantityPrice)}}</p>
                        </div>  
                        <div v-else class="flex flex-grow justify-end">
                            <p :class="{'text-right': name == 'orderSummary' }" class="flex items-center text-sm  font-semibold">&#8358;{{formatNumber(Amount)}}</p>
                        </div> 
                    </div>
                </div> 
            </div>    
        </div>
    </div>
    </div>
</template>

<script>

export default {
    name:['FoodCart'],
    props:['data','name'],
    data(){
        return{
            Quantity: 1,
            Amount:0,
            deleteItem:false,
        }
    },
    computed: {
        QuantityPrice(){
            this.Quantity = this.data.quantity
            this.Amount = this.data.amount
            return (this.data.quantity * this.data.amount) ? this.data.quantity * this.data.amount : 0
        }
    },
    methods:{
        addQty(){
            this.Quantity++
            this.QuantityPrice = this.Quantity * this.Amount
            this.updateCart(this.Quantity);

        },
        minusQty(){            
            if (this.Quantity > 1 ) {
                this.Quantity--
                this.QuantityPrice = this.Quantity * this.Amount
                this.updateCart(this.Quantity);
            } 
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        updateCart(Quantity){   
            let newcart = this.$store.getters.getCart
            newcart.cartItems.map((elem,index) => { 
                if (elem.foodId === this.data.foodId) {
                    elem.quantity = Quantity 
                }  
            })
            this.$store.dispatch('addCart',newcart)
        },
        deleteCart(){
            let newcart = this.$store.getters.getCart 
            let cart =newcart.cartItems.filter(item =>item.foodId !== this.data.foodId);
            newcart.cartItems = cart;
            this.$store.dispatch('addCart',newcart)
            //Update count array 
            if (this.name == 'orderSummary'|| this.name == 'mobileCart') {
                this.deleteItem = true
            }
            let count = this.$store.getters.getCart.cartItems.length + this.$store.getters.getCart.containers.length
            this.$store.dispatch('addCountcart',count);
        }
    }
   
    
}
</script>

<style>

</style>